.loginText {
    color: #007bff;
    font-weight: bold;
    cursor: pointer;
}

.successMessage {
    color: green;
    font-weight: bold;
}

.btn-secondary{
	cursor:pointer
}